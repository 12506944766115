import {  Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'jsonLd',
  standalone: true,
})
export class JsonLdPipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
  ) {}

  public transform(value: any): SafeHtml | undefined {
    if (!value) {
      return undefined;
    }

    // Sanitize the JSON value
    let sanitizedJson: string;
    try {
      sanitizedJson = JSON.stringify(value, null, 2)
        ?.replace(/<\/script>/gi, '<\\/script>') // Use case-insensitive replacement
        .replace(/[<>]/g, function(match) {      // Escape < and > characters
          return match === '<' ? '\\u003C' : '\\u003E';
        });
    } catch (e) {
      console.error('Failed to stringify JSON-LD:', e);
      return undefined;
    }

    return this.sanitizer.bypassSecurityTrustHtml(
      `<script type="application/ld+json">${sanitizedJson}</script>`
    );
  }
}
